import React from "react";
import "./styles.css";

export default function Spinner() {
  return (
    <svg className="progressSpinner" viewBox={"0 0 110 110"}>
      <circle
        cx="55"
        cy="55"
        r="40"
        stroke={"#B8F2D6"}
        strokeWidth={"15"}
        fillOpacity={0}
      ></circle>
      <circle className={"progressSpinnerPath"} cx="55" cy="55" r="40"></circle>
    </svg>
  );
}
