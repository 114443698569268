import {
  Order,
  OrderActiveStatusUpdateRequest,
  OrderApi,
  OrderArchiveRequest,
  OrderPaymentMethodEnum,
  OrderPostRequest,
  OrderPriceTypeEnum,
  OrderPutRequest,
  OrderStatusPostRequest,
  OrderStatusPutRequest,
  OrderTagPostRequest,
  OrderTagPutRequest,
  OrderTypeEnum,
  OrderUpdateDateRequest
} from "../../.generated/api";
import environment from "../../environment";
import { apiInstance } from "./ApiHttpBase";
import { useQuery } from "react-query";
import { QueryOptions } from "./ProjectApiV2";
import { toUTC } from "../../utils/DateTimeFunctions";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import i18n, { t } from "i18next";
import { createQuery } from "react-query-kit";

const orderApi = new OrderApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export type OrderSortField = "Title" | "OrderNumber";
export type OrderPaginatedSortField =
  | "Title"
  | "OrderNumber"
  | "Date"
  | "Status"
  | "Tags"
  | "ReferencePerson"
  | "CreatedAt"
  | "PriceType";

export const useGetPublicOrderById = createQuery({
  queryKey: ["getPublicOrderById"],
  fetcher: async (variables: { orderId: string }) => {
    const response = await orderApi.getPublicOrderInfoById(variables.orderId);
    return response.data;
  }
});

export const useGetPublicOrderInfoByIds = createQuery({
  queryKey: ["getPublicOrderInfoByIds"],
  fetcher: async (variables: { orderIds: string[] }) => {
    const response = await orderApi.getAllPublicOrderInfoByIds(
      variables.orderIds
    );
    return response.data;
  }
});

export const useGetOrderById = createQuery({
  queryKey: ["getOrderById"],
  fetcher: (variables: { orderId: string; companyId: string }) =>
    getOrderDetails(variables.companyId, variables.orderId)
});

export const useGetActiveOrdersByCompanyId = createQuery({
  queryKey: ["getActiveOrdersByCompanyId"],
  fetcher: async (variables: {
    companyId: string;
    withoutDate: boolean;
    page?: number;
    sortDirection?: "ASC" | "DESC";
    sortField?: OrderPaginatedSortField;
    query?: string;
    fromDate?: string;
    toDate?: string;
  }) => {
    const response = await orderApi.getPaginatedActiveCompanyOrders(
      variables.companyId,
      variables.withoutDate,
      undefined,
      variables.page,
      variables.sortDirection,
      variables.sortField,
      variables.query,
      variables.fromDate,
      variables.toDate
    );
    return response.data;
  }
});

export const orderQueries = createQueryKeys("order", {
  getById: (companyId: string, orderId: string) => ({
    queryKey: [orderId, companyId],
    queryFn: async () => {
      const response = await orderApi.getCompanyOrder(companyId, orderId);
      return response.data;
    }
  }),
  getActiveByFacilityId: (
    companyId: string,
    facilityId: string,
    wihtoutDate: boolean
  ) => ({
    queryKey: ["getActiveByFacilityId", companyId, facilityId, wihtoutDate],
    queryFn: async () => {
      const response = await orderApi.getPaginatedFacilityActiveOrders(
        companyId,
        facilityId,
        wihtoutDate
      );
      return response.data;
    }
  }),
  getByServiceContractId: (companyId: string, serviceContractId: string) => ({
    queryKey: ["getByServiceContractId", companyId, serviceContractId],
    queryFn: async () => {
      const response = await orderApi.getCompanyOrdersOnServiceContract(
        companyId,
        serviceContractId
      );
      return response.data;
    }
  }),
  getCompletedByCompanyId: (
    companyId: string,
    isInvoiced: boolean,
    page: number,
    sortDirection: "ASC" | "DESC",
    sortField: OrderPaginatedSortField,
    query?: string,
    fromDate?: string,
    toDate?: string
  ) => ({
    queryKey: [
      "getCompletedByCompanyId",
      companyId,
      isInvoiced,
      page,
      sortDirection,
      sortField,
      query,
      fromDate,
      toDate
    ],
    queryFn: async () => {
      const response = await orderApi.getPaginatedCompletedCompanyOrders(
        companyId,
        isInvoiced,
        undefined,
        page,
        sortDirection,
        sortField,
        query,
        fromDate,
        toDate
      );
      return response.data;
    }
  }),
  getOverdueByCompanyId: (companyId: string, page?: number) => ({
    queryKey: ["overdueByCompanyId", companyId, page],
    queryFn: async () => {
      const response = await orderApi.getOverdueOrdersByCompanyId(
        companyId,
        page
      );
      return response.data;
    }
  }),
  getOrdersByIdsPost: (
    companyId: string,
    orderIds: string[],
    page: number,
    sortDirection: "ASC" | "DESC",
    sortField: OrderPaginatedSortField
  ) => ({
    queryKey: ["activeByIds", companyId, orderIds],
    queryFn: async () => {
      const response = await orderApi.getOrdersByIdsPost(
        companyId,
        {
          orderIds
        },
        page,
        sortDirection,
        sortField
      );
      return response.data;
    }
  }),
  getActiveByIds: (
    companyId: string,
    withoutDate: boolean,
    orderIds: string[],
    page: number,
    sortDirection: "ASC" | "DESC",
    sortField: OrderPaginatedSortField,
    query?: string,
    fromDate?: string,
    toDate?: string
  ) => ({
    queryKey: [
      "activeByIds",
      companyId,
      withoutDate,
      orderIds,
      page,
      sortDirection,
      sortField,
      query,
      fromDate,
      toDate
    ],
    queryFn: async () => {
      const response = await orderApi.getPaginatedActiveOrdersByIds(
        companyId,
        withoutDate,
        undefined,
        orderIds,
        page,
        sortDirection,
        sortField,
        query,
        fromDate,
        toDate
      );
      return response.data;
    }
  }),
  getCompletedByIds: (
    companyId: string,
    isInvoiced: boolean,
    orderIds: string[],
    page: number,
    sortDirection: "ASC" | "DESC",
    sortField: OrderPaginatedSortField,
    query?: string,
    fromDate?: string,
    toDate?: string
  ) => ({
    queryKey: [
      "completedByIds",
      companyId,
      isInvoiced,
      orderIds,
      page,
      sortDirection,
      sortField,
      query,
      fromDate,
      toDate
    ],
    queryFn: async () => {
      const response = await orderApi.getPaginatedCompletedOrdersByIds(
        companyId,
        isInvoiced,
        undefined,
        orderIds,
        page,
        sortDirection,
        sortField,
        query,
        fromDate,
        toDate
      );
      return response.data;
    }
  })
});

export function useSearchActiveOrdersByIds(
  companyId: string,
  withOutDate: boolean,
  orderIds: string[],
  page: number,
  sortDirection: "ASC" | "DESC" = "DESC",
  sortField:
    | "Title"
    | "OrderNumber"
    | "Date"
    | "Status"
    | "Tags"
    | "ReferencePerson"
    | "CreatedAt"
    | "PriceType",
  search: string,
  options?: QueryOptions
) {
  return useQuery(
    ["searchOrders", companyId, orderIds, withOutDate],
    async () => {
      const response = await orderApi.getPaginatedActiveOrdersByIds(
        companyId,
        withOutDate,
        undefined,
        orderIds,
        page,
        sortDirection,
        sortField,
        search
      );
      return response.data;
    },
    options
  );
}

export function useSearchCompletedOrdersByIds(
  companyId: string,
  withOutDate: boolean,
  orderIds: string[],
  page: number,
  sortDirection: "ASC" | "DESC" = "DESC",
  sortField:
    | "Title"
    | "OrderNumber"
    | "Date"
    | "Status"
    | "Tags"
    | "ReferencePerson"
    | "CreatedAt"
    | "PriceType",
  search: string,
  options?: QueryOptions
) {
  return useQuery(
    ["searchOrdersCompleted", companyId, orderIds, withOutDate],
    async () => {
      const response = await orderApi.getPaginatedCompletedOrdersByIds(
        companyId,
        withOutDate,
        undefined,
        orderIds,
        page,
        sortDirection,
        sortField,
        search
      );
      return response.data;
    },
    options
  );
}

export function useGetArchivedOrders(companyId: string, page: number) {
  return useQuery(["archivedOrders", companyId, page], async () => {
    const response = await orderApi.getArchivedOrders(companyId, page);
    return response.data;
  });
}

export function useGetPaginatedCompanyOrders(
  companyId: string,
  page: number,
  sortDirection: "ASC" | "DESC",
  sortField: OrderSortField,
  query: string,
  fromDate?: Date,
  toDate?: Date,
  onlyCompleted?: boolean,
  onlyWithoutDate?: boolean,
  invoiced?: boolean,
  options?: QueryOptions
) {
  return useQuery(
    [
      "paginatedOrders",
      page,
      sortField,
      sortDirection,
      query,
      fromDate,
      toDate,
      onlyCompleted,
      onlyWithoutDate,
      invoiced
    ],
    async () => {
      const response = await orderApi.getPaginatedCompanyOrders(
        companyId,
        page,
        sortDirection,
        sortField,
        undefined,
        onlyCompleted,
        undefined,
        query,
        fromDate ? toUTC(fromDate).toISOString() : undefined,
        toDate ? toUTC(toDate).toISOString() : undefined,
        onlyWithoutDate,
        invoiced
      );
      return response.data;
    },
    options
  );
}

export function useGetOrdersByRecurringTemplate(
  companyId: string,
  templateId: string,
  onlyPending: boolean,
  page: number,
  options?: QueryOptions
) {
  return useQuery(
    ["recurringOrders", companyId, templateId, onlyPending, page],
    async () => {
      const response = await orderApi.getCompanyOrdersByRecurringTemplateId(
        companyId,
        templateId,
        onlyPending,
        page
      );
      return response.data;
    },
    options
  );
}

export async function getOrdersByIds(companyId: string, orderIds: string[]) {
  const response = await orderApi.getOrdersByIds(companyId, orderIds);
  return response.data;
}

export async function createCompanyOrder(
  companyId: string,
  request: OrderPostRequest
) {
  if (request.recurring !== undefined) {
    const { data } = await orderApi.postRecurringCompanyOrder(
      request,
      companyId
    );
    return data;
  } else {
    const { data } = await orderApi.postCompanyOrder(request, companyId);
    return [data.id];
  }
}

export async function getOrderDetails(companyId: string, orderId: string) {
  const { data } = await orderApi.getCompanyOrder(companyId, orderId);

  return data;
}

export async function updateOrderInspectionId(
  orderId: string,
  companyId: string,
  inspectionId: string
) {
  const response = await orderApi.updateOrderInspectionId(orderId, {
    inspectionId,
    companyId
  });
  return response.data;
}

export async function updateOrder(request: OrderPutRequest) {
  const { data } = await orderApi.putCompanyOrder(request);

  return data;
}

export async function updateOrderDate(request: OrderUpdateDateRequest) {
  const { data } = await orderApi.putCompanyOrderDate(request);

  return data;
}

export async function updateOrderActiveStatus(
  request: OrderActiveStatusUpdateRequest
) {
  const { data } = await orderApi.updateCompanyOrderStatus(request);

  return data;
}

export async function deleteOrder(request: OrderArchiveRequest) {
  await orderApi.archiveOrder(request);
}

export async function restoreOrder(request: OrderArchiveRequest) {
  await orderApi.restoreOrder(request);
}

// ------------------- STATUS -------------------
export async function getCompanyOrderStatuses(companyId: string) {
  const { data } = await orderApi.getCompanyOrderStatuses(companyId);

  return data;
}

export async function createCompanyOrderStatus(
  companyId: string,
  request: OrderStatusPostRequest
) {
  const { data } = await orderApi.postCompanyOrderStatus(request, companyId);

  return data;
}

export async function updateCompanyOrderStatus(request: OrderStatusPutRequest) {
  const { data } = await orderApi.putCompanyOrderStatus(request);

  return data;
}

export async function deleteCompanyOrderStatus(
  statusId: string,
  companyId: string
) {
  await orderApi.deleteCompanyOrderStatus(companyId, statusId);
}

// ------------------- TAG -------------------
export async function getCompanyOrderTags(companyId: string) {
  const { data } = await orderApi.getCompanyOrderTags(companyId);

  return data;
}

export async function createCompanyOrderTag(
  companyId: string,
  request: OrderTagPostRequest
) {
  const { data } = await orderApi.postCompanyOrderTag(request, companyId);

  return data;
}

export async function updateCompanyOrderTag(request: OrderTagPutRequest) {
  const { data } = await orderApi.putCompanyOrderTag(request);

  return data;
}

export function getOrderPriceTypeName(priceType: OrderPriceTypeEnum) {
  switch (priceType) {
    case "Fixed":
      return "fixed";
    case "Hourly":
      return "hourly";
    case "NonBillable":
      return "nonBillable";
  }
}

export function getOrderPriceText(order: Order) {
  if (order.priceType === "Hourly" || order.priceType === "Fixed") {
    return t(getOrderPriceTypeName(order.priceType));
  } else {
    return `${t(getOrderPriceTypeName(order.priceType))} (${t(
      getOrderTypeName(order.type)
    )})`;
  }
}

export function getPaymentMethodName(paymentMethod?: OrderPaymentMethodEnum) {
  switch (paymentMethod) {
    case "Cash":
      return t("card/cash");
    case "Invoice":
      return t("invoice");
    default:
      return "";
  }
}

export function getOrderTypeName(orderType?: OrderTypeEnum) {
  switch (orderType) {
    case "ServiceAgreement":
      return "Serviceavtale";
    case "Other":
      return "Annet";
    case "Claim":
      return "Reklamasjon";
    default:
      return "-";
  }
}

export async function deleteCompanyOrdertag(tagId: string, companyId: string) {
  await orderApi.deleteCompanyOrderTag(companyId, tagId);
}

// ----------------- Facility -----------------------

export function useSearchActiveOrdersByFacilityId(
  companyId: string,
  facilityId: string,
  withOutDate: boolean,
  page: number,
  sortDirection: "ASC" | "DESC" = "DESC",
  sortField:
    | "Title"
    | "OrderNumber"
    | "Date"
    | "Status"
    | "Tags"
    | "ReferencePerson"
    | undefined,
  search: string,
  options?: QueryOptions
) {
  return useQuery(
    ["searchFacilityOrders", companyId, facilityId, withOutDate],
    async () => {
      const response = await orderApi.getPaginatedFacilityActiveOrders(
        companyId,
        facilityId,
        withOutDate,
        undefined,
        page,
        sortDirection,
        sortField,
        search
      );
      return response.data;
    },
    options
  );
}

export function useSearchCompletedOrdersByFacilityId(
  companyId: string,
  facilityId: string,
  isInvoiced: boolean,
  page: number,
  sortDirection: "ASC" | "DESC" = "DESC",
  sortField:
    | "Title"
    | "OrderNumber"
    | "Date"
    | "Status"
    | "Tags"
    | "ReferencePerson"
    | undefined,
  search: string,
  options?: QueryOptions
) {
  return useQuery(
    ["searchOrders", companyId, facilityId, isInvoiced],
    async () => {
      const response = await orderApi.getPaginatedFacilityCompletedOrders(
        companyId,
        facilityId,
        isInvoiced,
        undefined,
        page,
        sortDirection,
        sortField,
        search
      );
      return response.data;
    },
    options
  );
}

export function convertOrderTitle(title: string): string {
  switch (title) {
    case "Ikke påbegynt":
      return i18n.t("notStarted");
    case "Under arbeid":
      return i18n.t("inProgress");
    case "Fullført":
      return i18n.t("completed");
    case "Fakturert":
      return i18n.t("invoiced");
    default:
      return title;
  }
}
