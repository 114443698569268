import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { orderDetailsTabs } from "../../../../../router/Routes";
import { useGetOrderById } from "../../../../../service/api/OrderApi";

const orderDetailsTabsSchema = z.object({
  tab: orderDetailsTabs.catch("overview").optional(),
  selectedDeviationId: z.string().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/orders/$orderId/details"
)({
  validateSearch: orderDetailsTabsSchema,
  loader: async ({
    context: { queryClient, companyId },
    params: { orderId }
  }) => {
    return queryClient.fetchQuery(
      useGetOrderById.getOptions({ orderId, companyId })
    );
  }
});
