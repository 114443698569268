import React, { Component, ReactNode } from "react";
import Button from "../../components/ds/Button";
import * as Sentry from "@sentry/react";

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
  error?: string;
};
export class ErrorBoundaryPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    Sentry.captureException(error);
    if (error.message.includes("Failed to fetch dynamically imported module")) {
      // location.reload();
    }

    this.setState({
      error: `${error.name}: ${error.message}`
    });
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <div
          className={"flex flex-col justify-center items-center h-screen gap-4"}
        >
          <h1>En feil har oppstått</h1>
          <Button icon={"restart_alt"} onClick={() => location.reload()}>
            Last inn siden på nytt
          </Button>
          <p className={"text-error text-body2"}>{this.state.error}</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundaryPage;
