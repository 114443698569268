import { getApps, initializeApp } from "firebase/app";
import React, { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import CustomToast from "./components/common/CustomToast";
import ErrorBoundaryPage from "./pages/errorBoundary/ErrorBoundaryPage";
import { ApiError } from "./service/ErrorHandler";
import { setDefaultOptions } from "date-fns";
import { nb } from "date-fns/locale";
import { QueryClientProvider as NewQueryClientProvider } from "@tanstack/react-query";
import { AppRouter, NewQueryClient } from "./router/AppRouter";
import { RouterProvider } from "@tanstack/react-router";
import environment from "./environment";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Theme } from "@radix-ui/themes";
import useTheme from "./hooks/useTheme";

const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null
    : React.lazy(() =>
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools
        }))
      );

export default function App() {
  useTheme();
  setDefaultOptions({ locale: nb });
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: (failureCount, e) => {
          const error = e as ApiError;
          if (error.status == 401 || error.status == 404) {
            return false;
          } else return failureCount < 2;
        }
      }
    }
  });

  if (!getApps().length) {
    initializeApp(environment.firebaseConfig);
  }

  return (
    <QueryClientProvider client={queryClient}>
      <NewQueryClientProvider client={NewQueryClient}>
        <Theme
          id={"radixThemeElement"}
          accentColor={"teal"}
          grayColor={"sage"}
          hasBackground={false}
          panelBackground={"solid"}
        >
          <ReactQueryDevtools />
          <CustomToast />
          <Suspense>
            <TanStackRouterDevtools router={AppRouter} />
          </Suspense>
          <ErrorBoundaryPage>
            <I18nextProvider i18n={i18n}></I18nextProvider>
            <RouterProvider router={AppRouter} />
          </ErrorBoundaryPage>
        </Theme>
      </NewQueryClientProvider>
    </QueryClientProvider>
  );
}
