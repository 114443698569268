import React from "react";
import Button from "../../components/ds/Button";
import { useTranslation } from "react-i18next";

type ErrorPageProps = {
  error: unknown;
};

export default function ErrorPage({ error }: ErrorPageProps) {
  const { t } = useTranslation();
  return (
    <div className={"flex flex-col justify-center items-center h-full gap-4"}>
      <h1>{t("En feil har oppstått")}</h1>
      <Button icon={"restart_alt"} onClick={() => location.reload()}>
        {t("Last inn siden på nytt")}
      </Button>
      <p className={"text-error text-body2 max-w-[400px]"}>
        {error?.toString()}
      </p>
    </div>
  );
}
