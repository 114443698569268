import classNames from "classnames";

export type IconProps = {
  icon: string;
  isWhite?: boolean;
  color?: string;
  className?: string;
  size?: "small" | "medium";
  onClick?: VoidFunction;
};

export default function Icon({
  icon,
  size = "medium",
  className,
  onClick,
  isWhite = false,
  color
}: IconProps) {
  const child = (
    <span
      className={classNames(
        "material-symbols-outlined",
        {
          "text-font-sm": size === "small",
          "text-font-md": size === "medium",
          "text-white": isWhite
        },
        className
      )}
      style={{ color: color }}
    >
      {icon}
    </span>
  );

  if (onClick) {
    return (
      <button
        onClick={onClick}
        className={classNames("flex", {
          "w-4 h-4": size === "small",
          "w-6 h-6": size === "medium"
        })}
        type={"button"}
      >
        {child}
      </button>
    );
  }

  return child;
}
