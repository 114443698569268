import {
  InvitationApi,
  InviteUserRequest,
  UserInvitation
} from "../../.generated/api";
import { apiInstance } from "./ApiHttpBase";
import environment from "../../environment";
import { createQuery } from "react-query-kit";

const invitationApi = new InvitationApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export const useGetPendingUserInvitations = createQuery({
  queryKey: ["getPendingUserInvitations"],
  fetcher: async () => {
    const response = await invitationApi.getUserInvitations(
      undefined,
      "pending"
    );
    return response.data;
  }
});

export async function getInvitations(
  companyId: string,
  status?: "accepted" | "declined" | "pending"
): Promise<UserInvitation[]> {
  const response = await invitationApi.getInvitations(
    companyId,
    undefined,
    status
  );
  return response.data;
}

export async function sendInvitation(
  companyId: string,
  request: InviteUserRequest
): Promise<void> {
  await invitationApi.createInvitation(companyId, request);
}

export async function deleteInvitation(
  invitationId: string,
  companyId: string
): Promise<void> {
  await invitationApi.deleteInvitation(invitationId, companyId);
}

export async function respondToInvitation(
  invitationId: string,
  accepted: boolean
) {
  await invitationApi.respondToInvitation({
    invitationId: invitationId,
    accepted: accepted
  });
}
