import {
  ChecklistFromTemplateRequest,
  ChecklistItem,
  ChecklistItemPutRequest,
  ChecklistItemTypeEnum,
  ChecklistPostRequest,
  ChecklistPutRequest,
  ChecklistV2,
  ChecklistV2Api,
  FileResponse
} from "../../.generated/api";
import environment from "../../environment";
import { apiInstance } from "./ApiHttpBase";
import { Control, UseFormRegister, UseFormWatch } from "react-hook-form";
import * as Yup from "yup";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { t } from "i18next";
import { createQuery } from "react-query-kit";

export type ChecklistFormInputs = {
  taskId?: string;
  title: string;
  description?: string;
  isRequired: boolean;
  recipients: string[];
  items: ChecklistItem[];
  saveAsTemplate: boolean;
};

export type ChecklistRawItem = {
  id?: string;
  index: number;
  title: string;
  type: ChecklistItemTypeEnum;
  possibleValues?: string[];
  files?: File[];
  uploadedFiles?: FileResponse[];
  description?: string;
  value?: string;
  completedAt?: string;
  completedBy?: string;
  comment?: string;
  required?: boolean;
};

export type ChecklistItemProps = {
  control: Control<ChecklistFormInputs>;
  register: UseFormRegister<ChecklistFormInputs>;
  watch: UseFormWatch<ChecklistFormInputs>;
  checklistItems?: ChecklistRawItem[];
};

export function getDefaultChecklistFormValues(checklist?: ChecklistV2) {
  const checklistFormInputs: ChecklistFormInputs = {
    title: checklist?.title ?? "",
    description: checklist?.description ?? undefined,
    items: checklist?.items
      ? checklist.items.sort((a, b) => a.index - b.index)
      : [],
    isRequired: checklist?.required ?? true,
    recipients: checklist?.recipients ?? [],
    saveAsTemplate: false,
    taskId: checklist?.resourceId
  };
  return checklistFormInputs;
}

export const checklistFormSchema = Yup.object().shape({
  title: Yup.string().required(t("fieldRequired")),
  items: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required("asd"),
      possibleValues: Yup.array().when("type", {
        is: "select",
        then: Yup.array()
          .min(2, "Minst to valg må legges til")
          .typeError("Minst to valg må legges til"),
        otherwise: Yup.array().nullable()
      })
    })
  ),
  recipients: Yup.array().of(Yup.string().email("Ugyldig e-post"))
});

const checklistApi = new ChecklistV2Api(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export const checklistQueries = createQueryKeys("checklist", {
  getByProjectId: (projectId: string) => ({
    queryKey: ["getByProjectId", projectId],
    queryFn: async () => {
      const response = await checklistApi.getChecklistByProjectId(projectId);
      return response.data;
    }
  }),
  getByResourceId: (resourceId: string) => ({
    queryKey: ["getByResourceId", resourceId],
    queryFn: async () => {
      const response = await checklistApi.getChecklistByResourceId(resourceId);
      return response.data;
    }
  }),
  getPublicByResourceId: (resourceId: string) => ({
    queryKey: ["getByResourceId", resourceId],
    queryFn: async () => {
      const response =
        await checklistApi.getAllPublicChecklistsByResourceId(resourceId);
      return response.data;
    }
  })
});

export const useGetChecklistByProjectId = createQuery({
  queryKey: ["getChecklistByProjectId"],
  fetcher: async (variables: { projectId: string }) => {
    const response = await checklistApi.getChecklistByProjectId(
      variables.projectId
    );
    return response.data;
  }
});

export const useGetChecklistByResourceId = createQuery({
  queryKey: ["getChecklistByResourceId"],
  fetcher: async (variables: { resourceId: string }) => {
    const response = await checklistApi.getChecklistByResourceId(
      variables.resourceId
    );
    return response.data;
  }
});

export const useGetChecklistById = createQuery({
  queryKey: ["getChecklistById"],
  fetcher: async (variables: { checklistId: string }) => {
    const response = await checklistApi.getChecklistById(variables.checklistId);
    return response.data;
  }
});

export async function createChecklist(request: ChecklistPostRequest) {
  const response = await checklistApi.createChecklist(request);
  return response.data;
}

export async function createChecklistFromTemplate(
  request: ChecklistFromTemplateRequest
) {
  const response = await checklistApi.createFromTemplate(request);
  return response.data;
}

export async function deleteChecklist(checklistId: string) {
  await checklistApi.deleteChecklist(checklistId);
}

export async function updateChecklist(request: ChecklistPutRequest) {
  const res = await checklistApi.updateChecklist(request);
  return res.data;
}

export async function updateChecklistItemInputValueData(
  checklistId: string,
  request: Array<ChecklistItemPutRequest>
): Promise<void> {
  await checklistApi.updateChecklistItems(checklistId, request);
}
