import { t } from "i18next";

function padNumber(date: number) {
  if (date >= 10) return date;
  return `0${date}`;
}

// 18. august 2022 kl. 21:23
export const dateTimeFormatter = (dateString?: string): string => {
  if (dateString == null) {
    return "-";
  } else {
    const date = new Date(dateString);
    const formatter = Intl.DateTimeFormat(t("nb"), {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric"
    });

    return formatter.format(date);
  }
};

// 18. august 2022
export const dateFormatter = (dateString?: string): string => {
  if (dateString == null) return "";

  const date = new Date(dateString);
  const formatter = Intl.DateTimeFormat(t("nb"), {
    month: "long",
    day: "numeric",
    year: "numeric"
  });

  return formatter.format(date);
};
// 18. aug 2022
export const dateFormatterShortMonth = (date: Date): string => {
  if (date == null) return "";

  const formatter = Intl.DateTimeFormat(t("nb"), {
    month: "short",
    day: "numeric",
    year: "numeric"
  });

  return formatter.format(date);
};

export function formatDateAndMonthString(dateString?: string) {
  if (!dateString) return "";
  try {
    const date = new Date(dateString);
    return formatDateAndMonth(date);
  } catch (e) {
    return "";
  }
}

// 18. august
export function formatDateAndMonth(date: Date): string {
  return Intl.DateTimeFormat(t("nb"), {
    day: "numeric",
    month: "long"
  }).format(date);
}

// August
export function formatMonth(date: Date, length?: "long" | "short"): string {
  const formattedMonth = Intl.DateTimeFormat(t("nb"), {
    month: length ?? "long"
  }).format(date);

  return formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1);
}

// 2022
export function formatYear(date: Date) {
  const formattedYear = Intl.DateTimeFormat(t("nb"), {
    year: "numeric"
  }).format(date);

  return formattedYear;
}

// 18.08
export function formatNumericDayAndMonth(date: Date): string {
  return `${padNumber(date.getDate())}.${padNumber(date.getMonth() + 1)}`;
}

// 18. august kl. 21:23
export function formatDayMonthAndTime(dateString: string): string {
  try {
    const date = new Date(dateString);
    const formatter = Intl.DateTimeFormat(t("nb"), {
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric"
    });

    return formatter.format(date);
  } catch (e) {
    return "-";
  }
}

// kl. 18:00
export function formatTime(dateString: string): string {
  try {
    const date = new Date(dateString);
    const formatter = Intl.DateTimeFormat(t("nb"), {
      hour: "numeric",
      minute: "numeric"
    });
    return `${t("kl.")} ${formatter.format(date)}`;
  } catch (error) {
    return "-";
  }
}

export function formatFullNumericDateTimeString(dateString: string) {
  try {
    const date = new Date(dateString);
    return formatFullNumericDateTime(date);
  } catch (e) {
    return "";
  }
}

export function formatFullNumericDateString(dateString?: string) {
  if (!dateString) return "";
  try {
    const date = new Date(dateString);
    return formatFullNumericDate(date);
  } catch (e) {
    return "";
  }
}

export function formatWeekDay(date?: Date) {
  if (!date) return "";
  return Intl.DateTimeFormat(t("nb"), {
    weekday: "long"
  }).format(date);
}

// 18.08.22
export function formatFullNumericDate(date?: Date) {
  if (!date) return "";
  const formattedDate = Intl.DateTimeFormat(t("nb"), {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit"
  }).format(date);

  return formattedDate;
}

export function formatFullNumericDateTime(date?: Date) {
  if (!date) return "";
  const formattedDate = Intl.DateTimeFormat(t("nb"), {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  }).format(date);

  return formattedDate;
}

// 21:23
export function formatHoursAndMinutes(date?: Date) {
  if (!date) return "";
  return Intl.DateTimeFormat(t("nb"), {
    hour: "2-digit",
    minute: "2-digit"
  }).format(date);
}

export function formatHoursAndMinutesString(date?: string) {
  if (!date) return "";
  try {
    const time = new Date(date);
    return Intl.DateTimeFormat(t("nb"), {
      hour: "2-digit",
      minute: "2-digit"
    }).format(time);
  } catch (e) {
    return "";
  }
}

export function formatShortDayDateAndMonth(date?: string) {
  if (!date) return "";
  try {
    const time = new Date(date);
    return Intl.DateTimeFormat("nb", {
      weekday: "short",
      day: "2-digit",
      month: "2-digit"
    }).format(time);
  } catch (e) {
    return "";
  }
}

// 18. aug., 21:23
export function getChatTimestamp(
  dateString: string,
  lastDateString?: string
): string {
  try {
    const timestamp = new Date(dateString);
    if (lastDateString) {
      const lastTimestamp = new Date(lastDateString);
      const timeDiff = // i minutter
        (timestamp.getTime() - lastTimestamp.getTime()) / (1000 * 60);
      if (timeDiff < 3) return "";
    }
    const timestampString = formatChatTimeStamp(timestamp);
    return timestampString;
  } catch (e) {
    return "-";
  }
}

// 13:14 || tor. 12:12 || 31.10.2022, 08:54
export function formatChatTimeStamp(date: Date): string {
  try {
    const now = new Date();
    if (isSameDay(date, now)) {
      const formatter = new Intl.DateTimeFormat(t("nb"), {
        hour: "numeric",
        minute: "numeric"
      });
      return formatter.format(date);
    }
    if (getDayDifference(now, date) <= 6) {
      const formatter = new Intl.DateTimeFormat(t("nb"), {
        weekday: "short",
        hour: "2-digit",
        minute: "2-digit"
      });
      return formatter.format(date).replace("kl. ", "");
    }

    const formatter = new Intl.DateTimeFormat(t("nb"), {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric"
    });

    return formatter.format(date);
  } catch (e) {
    return "";
  }
}

export function isSameDay(d1: Date, d2: Date): boolean {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

const CURRENT_DAY = 1;

// 731
export function getDayDifference(d1: Date, d2: Date): number {
  const utc1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate());
  const utc2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate());
  const days = (utc1 - utc2) / (1000 * 3600 * 24);
  return Math.floor(days) + CURRENT_DAY;
}

// Lør, Søn,
export function getWeekDayShort(date: Date) {
  const day = date.toLocaleDateString(t("nb-NO"), { weekday: "short" });
  return day.substring(0, 1).toUpperCase() + day.substring(1);
}

// 04.02.23 - 04.02.23 (1 dag)
// 04.02.23 - 06.02.23 (3 dager)
export function formatToFromDateWithDayDifference(
  startDate: Date | undefined,
  endDate: Date | undefined
) {
  if (!(startDate && endDate)) return undefined;

  const numberOfDays = getDayDifference(endDate, startDate);

  const daySuffix = numberOfDays > 1 ? t("dager") : t("dag");

  return `${formatFullNumericDate(startDate)} - ${formatFullNumericDate(
    endDate
  )} (${numberOfDays} ${daySuffix})`;
}
