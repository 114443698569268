import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { projectDetailsTabs } from "../../../../../router/Routes";
import { useGetProjectById } from "../../../../../service/api/ProjectApiV2";

const projectDetailsTabSchema = z.object({
  tab: projectDetailsTabs.catch("overview").optional(),
  selectedDeviationId: z.string().optional()
});

export const Route = createFileRoute(
  "/_protected/dashboard/project/$projectId/details"
)({
  validateSearch: projectDetailsTabSchema,
  loader: async ({ context: { queryClient }, params: { projectId } }) => {
    await queryClient.ensureQueryData({
      ...useGetProjectById.getOptions({ projectId })
    });
  }
});
