import {
  FacilityApi,
  FacilityPostRequest,
  FacilityPutRequest
} from "../../.generated/api";
import environment from "../../environment";
import { apiInstance } from "./ApiHttpBase";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { createQuery } from "react-query-kit";

const facilityApi = new FacilityApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export const useGetFacilityById = createQuery({
  queryKey: ["getFacilityById"],
  fetcher: async (variables: { companyId: string; facilityId: string }) => {
    const response = await facilityApi.getFacilityOnId(
      variables.companyId,
      variables.facilityId
    );
    return response.data;
  }
});

export const facilityQueries = createQueryKeys("facility", {
  getByCompanyId: (
    companyId: string,
    query: string,
    page?: number,
    archived = false
  ) => ({
    queryKey: [companyId, query, page, archived],
    queryFn: async () => {
      const response = await facilityApi.getAllCompanyFacilities(
        companyId,
        page,
        "DESC",
        "FacilityNumber",
        query,
        archived
      );
      return response.data;
    }
  }),
  getByIds: (
    companyId: string,
    facilityIds: string[],
    query: string,
    page?: number,
    archived = false
  ) => ({
    queryKey: ["getByIds", companyId, facilityIds, query, page, archived],
    queryFn: async () => {
      const response = await facilityApi.getAllByIds(
        companyId,
        facilityIds,
        page,
        "DESC",
        "FacilityNumber",
        query,
        archived
      );
      return response.data;
    }
  })
});

export async function createFacility(request: FacilityPostRequest) {
  const { data } = await facilityApi.postFacility(request);
  return data;
}

export async function updateFacility(request: FacilityPutRequest) {
  const { data } = await facilityApi.updateFacility(request);
  return data;
}

export async function archiveFacility(companyId: string, facilityId: string) {
  const { data } = await facilityApi.archiveFacility(facilityId, companyId);
  return data;
}

export async function restoreFacility(companyId: string, facilityId: string) {
  await facilityApi.restoreFacility(facilityId, companyId);
  // return data;
}
