import { UserCompanyConnection } from "../.generated/api";
import { userQueries } from "../service/api/UserApi";
import { CompanyInfo, useAppStore } from "../state/app_store";
import { QueryClient } from "@tanstack/react-query";
import { redirect } from "@tanstack/react-router";
import { useGetPendingUserInvitations } from "../service/api/InvitationApi";

export function getActiveConnections(
  companyConnections?: UserCompanyConnection[]
) {
  const today = Date.now();
  return companyConnections?.filter(
    (c: UserCompanyConnection) => !c.endDate || today < Date.parse(c.endDate)
  );
}

export async function refetchCompany(queryClient: QueryClient) {
  const user = await queryClient.fetchQuery(userQueries.getProfile());

  const selectedCompany = useAppStore.getState().selectedCompany;
  const company = user.companyConnections?.find(
    (c) => c.companyId === selectedCompany?.id
  );

  if (!company) return;
  useAppStore.setState({ selectedCompany: mapCompanyData(company) });
}

export async function resolveCompany(queryClient: QueryClient) {
  const user = await queryClient.ensureQueryData(userQueries.getProfile());
  const invitations = await queryClient.ensureQueryData(
    useGetPendingUserInvitations.getOptions()
  );

  const savedCompany = useAppStore.getState().selectedCompany;
  const activeConnections = getActiveConnections(user.companyConnections);
  const selectedCompany = activeConnections?.find(
    (c) => c.companyId === savedCompany?.id
  );

  useAppStore.setState({ user: user });
  if (!user.name || !user.phoneNumber) {
    throw redirect({
      to: "/userInfo"
    });
  }

  if (invitations.length > 0) {
    throw redirect({
      to: "/invitations"
    });
  }

  if (activeConnections?.length === 0 || activeConnections === undefined) {
    throw redirect({
      to: "/createCompany"
    });
  }

  const activeCompany = selectedCompany ?? activeConnections[0];
  useAppStore.setState({ selectedCompany: mapCompanyData(activeCompany) });
  if (activeCompany.companySubscription === "EXPIRED") {
    throw redirect({
      to: "/trial/expired"
    });
  }

  return activeCompany.companyId;
}

export function mapCompanyData(company: UserCompanyConnection): CompanyInfo {
  return {
    name: company.companyName,
    id: company.companyId,
    freeTrialDaysRemaining: company.freeTrialDaysRemaining,
    subscription: company.companySubscription,
    orgNumber: company.companyOrgNumber,
    phone: company.contactPhone,
    email: company.contactEmail,
    contactPerson: company.contactPerson,
    features: {
      projects: company.projectsEnabled,
      products: company.productsEnabled,
      orders: company.ordersEnabled,
      sales: company.salesEnabled,
      resourcePlanner: company.resourcePlannerEnabled,
      tasks: company.tasksEnabled,
      timekeeping: company.timekeepingEnabled
    }
  };
}
