/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { PaginatedResponseProjectV2 } from '../model';
// @ts-ignore
import { ProjectPostRequest } from '../model';
// @ts-ignore
import { ProjectPutRequest } from '../model';
// @ts-ignore
import { ProjectV2 } from '../model';
// @ts-ignore
import { PublicResourceInfo } from '../model';
// @ts-ignore
import { PublicResourcePutRequest } from '../model';
/**
 * ProjectV2Api - axios parameter creator
 * @export
 */
export const ProjectV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new project
         * @param {ProjectPostRequest} projectPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (projectPostRequest: ProjectPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectPostRequest' is not null or undefined
            assertParamExists('createProject', 'projectPostRequest', projectPostRequest)
            const localVarPath = `/v2/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all company projects between dates
         * @param {string} companyId 
         * @param {string} from 
         * @param {string} to 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyProjectsBetweenDates: async (companyId: string, from: string, to: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyProjectsBetweenDates', 'companyId', companyId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getCompanyProjectsBetweenDates', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getCompanyProjectsBetweenDates', 'to', to)
            const localVarPath = `/v2/company/{companyId}/project/all/range`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company root projects between dates
         * @param {string} companyId 
         * @param {string} from 
         * @param {string} to 
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyRootProjectsBetweenDates: async (companyId: string, from: string, to: string, authorization?: string, status?: 'active' | 'deleted', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyRootProjectsBetweenDates', 'companyId', companyId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getCompanyRootProjectsBetweenDates', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getCompanyRootProjectsBetweenDates', 'to', to)
            const localVarPath = `/v2/company/{companyId}/project/range`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company root projects
         * @param {string} companyId 
         * @param {string} query 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyRootProjectsNew: async (companyId: string, query: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyRootProjectsNew', 'companyId', companyId)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('getCompanyRootProjectsNew', 'query', query)
            const localVarPath = `/v2/company/{companyId}/project`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search active projects from list, paginated
         * @param {string} companyId 
         * @param {string} query 
         * @param {'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle'} sortField 
         * @param {string} [authorization] 
         * @param {Array<string>} [projectIds] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedActiveProjectsByIds: async (companyId: string, query: string, sortField: 'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle', authorization?: string, projectIds?: Array<string>, page?: number, sortDirection?: 'ASC' | 'DESC', fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPaginatedActiveProjectsByIds', 'companyId', companyId)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('getPaginatedActiveProjectsByIds', 'query', query)
            // verify required parameter 'sortField' is not null or undefined
            assertParamExists('getPaginatedActiveProjectsByIds', 'sortField', sortField)
            const localVarPath = `/v2/company/{companyId}/projects/list/paginated`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectIds) {
                localVarQueryParameter['projectIds'] = projectIds;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project by id
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById: async (projectId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectById', 'projectId', projectId)
            const localVarPath = `/v2/project/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get projects by IDs
         * @param {string} companyId 
         * @param {Array<string>} [projectIds] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByIds: async (companyId: string, projectIds?: Array<string>, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getProjectsByIds', 'companyId', companyId)
            const localVarPath = `/v2/company/{companyId}/projects`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectIds) {
                localVarQueryParameter['projectIds'] = projectIds;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get projects by parent ID
         * @param {string} projectId 
         * @param {'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle'} sortField 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByParentId: async (projectId: string, sortField: 'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle', query?: string, page?: number, sortDirection?: 'ASC' | 'DESC', authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectsByParentId', 'projectId', projectId)
            // verify required parameter 'sortField' is not null or undefined
            assertParamExists('getProjectsByParentId', 'sortField', sortField)
            const localVarPath = `/v2/project/{projectId}/children`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get projects by parent ID (List)
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByParentIdAsList: async (projectId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectsByParentIdAsList', 'projectId', projectId)
            const localVarPath = `/v2/project/{projectId}/children/list`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public project by id
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicProjectInfoById: async (projectId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getPublicProjectInfoById', 'projectId', projectId)
            const localVarPath = `/v2/public/project/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public projects by list of ids
         * @param {Array<string>} projectIds 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicProjectInfoByIds: async (projectIds: Array<string>, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectIds' is not null or undefined
            assertParamExists('getPublicProjectInfoByIds', 'projectIds', projectIds)
            const localVarPath = `/v2/public/project/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectIds) {
                localVarQueryParameter['projectIds'] = projectIds;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get users project between dates
         * @param {string} companyId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProjects: async (companyId: string, from?: string, to?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getUserProjects', 'companyId', companyId)
            const localVarPath = `/v2/company/{companyId}/project/user`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get users root project on company
         * @param {string} companyId 
         * @param {'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle'} sortField 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRootProjects: async (companyId: string, sortField: 'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle', page?: number, sortDirection?: 'ASC' | 'DESC', authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getUserRootProjects', 'companyId', companyId)
            // verify required parameter 'sortField' is not null or undefined
            assertParamExists('getUserRootProjects', 'sortField', sortField)
            const localVarPath = `/v2/company/{companyId}/project/user/root`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search company projects
         * @param {string} companyId 
         * @param {string} query 
         * @param {'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle'} sortField 
         * @param {string} [authorization] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCompanyProjects: async (companyId: string, query: string, sortField: 'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle', authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('searchCompanyProjects', 'companyId', companyId)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('searchCompanyProjects', 'query', query)
            // verify required parameter 'sortField' is not null or undefined
            assertParamExists('searchCompanyProjects', 'sortField', sortField)
            const localVarPath = `/v2/company/{companyId}/project/all/search`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company root projects between dates
         * @param {string} companyId 
         * @param {'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle'} sortField 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCompanyRootProjects: async (companyId: string, sortField: 'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle', query?: string, page?: number, sortDirection?: 'ASC' | 'DESC', authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('searchCompanyRootProjects', 'companyId', companyId)
            // verify required parameter 'sortField' is not null or undefined
            assertParamExists('searchCompanyRootProjects', 'sortField', sortField)
            const localVarPath = `/v2/company/{companyId}/project/search`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set project last updated timestamp
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProjectUpdatedTimestamp: async (projectId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('setProjectUpdatedTimestamp', 'projectId', projectId)
            const localVarPath = `/v2/project/{projectId}/updateTimestamp`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update project
         * @param {string} projectId 
         * @param {ProjectPutRequest} projectPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (projectId: string, projectPutRequest: ProjectPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateProject', 'projectId', projectId)
            // verify required parameter 'projectPutRequest' is not null or undefined
            assertParamExists('updateProject', 'projectPutRequest', projectPutRequest)
            const localVarPath = `/v2/project/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Public View flag on project resources
         * @param {string} resourceId 
         * @param {'task' | 'project' | 'company' | 'deviation' | 'checklist' | 'checklistItem' | 'documentation' | 'documentationConnection' | 'location' | 'ehs' | 'order' | 'internalRoutine' | 'progressPlan'} resourceType 
         * @param {Array<PublicResourcePutRequest>} publicResourcePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublicResources: async (resourceId: string, resourceType: 'task' | 'project' | 'company' | 'deviation' | 'checklist' | 'checklistItem' | 'documentation' | 'documentationConnection' | 'location' | 'ehs' | 'order' | 'internalRoutine' | 'progressPlan', publicResourcePutRequest: Array<PublicResourcePutRequest>, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('updatePublicResources', 'resourceId', resourceId)
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('updatePublicResources', 'resourceType', resourceType)
            // verify required parameter 'publicResourcePutRequest' is not null or undefined
            assertParamExists('updatePublicResources', 'publicResourcePutRequest', publicResourcePutRequest)
            const localVarPath = `/v2/resource/{resourceType}/{resourceId}/updatePublicView`
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)))
                .replace(`{${"resourceType"}}`, encodeURIComponent(String(resourceType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publicResourcePutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectV2Api - functional programming interface
 * @export
 */
export const ProjectV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new project
         * @param {ProjectPostRequest} projectPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(projectPostRequest: ProjectPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(projectPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all company projects between dates
         * @param {string} companyId 
         * @param {string} from 
         * @param {string} to 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyProjectsBetweenDates(companyId: string, from: string, to: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyProjectsBetweenDates(companyId, from, to, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company root projects between dates
         * @param {string} companyId 
         * @param {string} from 
         * @param {string} to 
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyRootProjectsBetweenDates(companyId: string, from: string, to: string, authorization?: string, status?: 'active' | 'deleted', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyRootProjectsBetweenDates(companyId, from, to, authorization, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company root projects
         * @param {string} companyId 
         * @param {string} query 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyRootProjectsNew(companyId: string, query: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyRootProjectsNew(companyId, query, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search active projects from list, paginated
         * @param {string} companyId 
         * @param {string} query 
         * @param {'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle'} sortField 
         * @param {string} [authorization] 
         * @param {Array<string>} [projectIds] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedActiveProjectsByIds(companyId: string, query: string, sortField: 'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle', authorization?: string, projectIds?: Array<string>, page?: number, sortDirection?: 'ASC' | 'DESC', fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseProjectV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedActiveProjectsByIds(companyId, query, sortField, authorization, projectIds, page, sortDirection, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get project by id
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectById(projectId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectById(projectId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get projects by IDs
         * @param {string} companyId 
         * @param {Array<string>} [projectIds] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsByIds(companyId: string, projectIds?: Array<string>, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsByIds(companyId, projectIds, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get projects by parent ID
         * @param {string} projectId 
         * @param {'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle'} sortField 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsByParentId(projectId: string, sortField: 'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle', query?: string, page?: number, sortDirection?: 'ASC' | 'DESC', authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseProjectV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsByParentId(projectId, sortField, query, page, sortDirection, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get projects by parent ID (List)
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsByParentIdAsList(projectId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsByParentIdAsList(projectId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get public project by id
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicProjectInfoById(projectId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicResourceInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicProjectInfoById(projectId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get public projects by list of ids
         * @param {Array<string>} projectIds 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicProjectInfoByIds(projectIds: Array<string>, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicResourceInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicProjectInfoByIds(projectIds, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get users project between dates
         * @param {string} companyId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProjects(companyId: string, from?: string, to?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProjects(companyId, from, to, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get users root project on company
         * @param {string} companyId 
         * @param {'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle'} sortField 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRootProjects(companyId: string, sortField: 'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle', page?: number, sortDirection?: 'ASC' | 'DESC', authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseProjectV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRootProjects(companyId, sortField, page, sortDirection, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search company projects
         * @param {string} companyId 
         * @param {string} query 
         * @param {'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle'} sortField 
         * @param {string} [authorization] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCompanyProjects(companyId: string, query: string, sortField: 'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle', authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseProjectV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchCompanyProjects(companyId, query, sortField, authorization, page, sortDirection, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company root projects between dates
         * @param {string} companyId 
         * @param {'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle'} sortField 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCompanyRootProjects(companyId: string, sortField: 'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle', query?: string, page?: number, sortDirection?: 'ASC' | 'DESC', authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseProjectV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchCompanyRootProjects(companyId, sortField, query, page, sortDirection, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set project last updated timestamp
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setProjectUpdatedTimestamp(projectId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseProjectV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setProjectUpdatedTimestamp(projectId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update project
         * @param {string} projectId 
         * @param {ProjectPutRequest} projectPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(projectId: string, projectPutRequest: ProjectPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(projectId, projectPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Public View flag on project resources
         * @param {string} resourceId 
         * @param {'task' | 'project' | 'company' | 'deviation' | 'checklist' | 'checklistItem' | 'documentation' | 'documentationConnection' | 'location' | 'ehs' | 'order' | 'internalRoutine' | 'progressPlan'} resourceType 
         * @param {Array<PublicResourcePutRequest>} publicResourcePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePublicResources(resourceId: string, resourceType: 'task' | 'project' | 'company' | 'deviation' | 'checklist' | 'checklistItem' | 'documentation' | 'documentationConnection' | 'location' | 'ehs' | 'order' | 'internalRoutine' | 'progressPlan', publicResourcePutRequest: Array<PublicResourcePutRequest>, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePublicResources(resourceId, resourceType, publicResourcePutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectV2Api - factory interface
 * @export
 */
export const ProjectV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectV2ApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new project
         * @param {ProjectPostRequest} projectPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(projectPostRequest: ProjectPostRequest, authorization?: string, options?: any): AxiosPromise<ProjectV2> {
            return localVarFp.createProject(projectPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all company projects between dates
         * @param {string} companyId 
         * @param {string} from 
         * @param {string} to 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyProjectsBetweenDates(companyId: string, from: string, to: string, authorization?: string, options?: any): AxiosPromise<Array<ProjectV2>> {
            return localVarFp.getCompanyProjectsBetweenDates(companyId, from, to, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company root projects between dates
         * @param {string} companyId 
         * @param {string} from 
         * @param {string} to 
         * @param {string} [authorization] 
         * @param {'active' | 'deleted'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyRootProjectsBetweenDates(companyId: string, from: string, to: string, authorization?: string, status?: 'active' | 'deleted', options?: any): AxiosPromise<Array<ProjectV2>> {
            return localVarFp.getCompanyRootProjectsBetweenDates(companyId, from, to, authorization, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company root projects
         * @param {string} companyId 
         * @param {string} query 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyRootProjectsNew(companyId: string, query: string, authorization?: string, options?: any): AxiosPromise<Array<ProjectV2>> {
            return localVarFp.getCompanyRootProjectsNew(companyId, query, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search active projects from list, paginated
         * @param {string} companyId 
         * @param {string} query 
         * @param {'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle'} sortField 
         * @param {string} [authorization] 
         * @param {Array<string>} [projectIds] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedActiveProjectsByIds(companyId: string, query: string, sortField: 'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle', authorization?: string, projectIds?: Array<string>, page?: number, sortDirection?: 'ASC' | 'DESC', fromDate?: string, toDate?: string, options?: any): AxiosPromise<PaginatedResponseProjectV2> {
            return localVarFp.getPaginatedActiveProjectsByIds(companyId, query, sortField, authorization, projectIds, page, sortDirection, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project by id
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById(projectId: string, authorization?: string, options?: any): AxiosPromise<ProjectV2> {
            return localVarFp.getProjectById(projectId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get projects by IDs
         * @param {string} companyId 
         * @param {Array<string>} [projectIds] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByIds(companyId: string, projectIds?: Array<string>, authorization?: string, options?: any): AxiosPromise<Array<ProjectV2>> {
            return localVarFp.getProjectsByIds(companyId, projectIds, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get projects by parent ID
         * @param {string} projectId 
         * @param {'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle'} sortField 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByParentId(projectId: string, sortField: 'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle', query?: string, page?: number, sortDirection?: 'ASC' | 'DESC', authorization?: string, options?: any): AxiosPromise<PaginatedResponseProjectV2> {
            return localVarFp.getProjectsByParentId(projectId, sortField, query, page, sortDirection, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get projects by parent ID (List)
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByParentIdAsList(projectId: string, authorization?: string, options?: any): AxiosPromise<Array<ProjectV2>> {
            return localVarFp.getProjectsByParentIdAsList(projectId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get public project by id
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicProjectInfoById(projectId: string, authorization?: string, options?: any): AxiosPromise<PublicResourceInfo> {
            return localVarFp.getPublicProjectInfoById(projectId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get public projects by list of ids
         * @param {Array<string>} projectIds 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicProjectInfoByIds(projectIds: Array<string>, authorization?: string, options?: any): AxiosPromise<Array<PublicResourceInfo>> {
            return localVarFp.getPublicProjectInfoByIds(projectIds, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get users project between dates
         * @param {string} companyId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProjects(companyId: string, from?: string, to?: string, authorization?: string, options?: any): AxiosPromise<Array<ProjectV2>> {
            return localVarFp.getUserProjects(companyId, from, to, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get users root project on company
         * @param {string} companyId 
         * @param {'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle'} sortField 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRootProjects(companyId: string, sortField: 'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle', page?: number, sortDirection?: 'ASC' | 'DESC', authorization?: string, options?: any): AxiosPromise<PaginatedResponseProjectV2> {
            return localVarFp.getUserRootProjects(companyId, sortField, page, sortDirection, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search company projects
         * @param {string} companyId 
         * @param {string} query 
         * @param {'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle'} sortField 
         * @param {string} [authorization] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCompanyProjects(companyId: string, query: string, sortField: 'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle', authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', fromDate?: string, toDate?: string, options?: any): AxiosPromise<PaginatedResponseProjectV2> {
            return localVarFp.searchCompanyProjects(companyId, query, sortField, authorization, page, sortDirection, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company root projects between dates
         * @param {string} companyId 
         * @param {'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle'} sortField 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCompanyRootProjects(companyId: string, sortField: 'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle', query?: string, page?: number, sortDirection?: 'ASC' | 'DESC', authorization?: string, options?: any): AxiosPromise<PaginatedResponseProjectV2> {
            return localVarFp.searchCompanyRootProjects(companyId, sortField, query, page, sortDirection, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set project last updated timestamp
         * @param {string} projectId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProjectUpdatedTimestamp(projectId: string, authorization?: string, options?: any): AxiosPromise<PaginatedResponseProjectV2> {
            return localVarFp.setProjectUpdatedTimestamp(projectId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update project
         * @param {string} projectId 
         * @param {ProjectPutRequest} projectPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(projectId: string, projectPutRequest: ProjectPutRequest, authorization?: string, options?: any): AxiosPromise<ProjectV2> {
            return localVarFp.updateProject(projectId, projectPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Public View flag on project resources
         * @param {string} resourceId 
         * @param {'task' | 'project' | 'company' | 'deviation' | 'checklist' | 'checklistItem' | 'documentation' | 'documentationConnection' | 'location' | 'ehs' | 'order' | 'internalRoutine' | 'progressPlan'} resourceType 
         * @param {Array<PublicResourcePutRequest>} publicResourcePutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublicResources(resourceId: string, resourceType: 'task' | 'project' | 'company' | 'deviation' | 'checklist' | 'checklistItem' | 'documentation' | 'documentationConnection' | 'location' | 'ehs' | 'order' | 'internalRoutine' | 'progressPlan', publicResourcePutRequest: Array<PublicResourcePutRequest>, authorization?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updatePublicResources(resourceId, resourceType, publicResourcePutRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectV2Api - object-oriented interface
 * @export
 * @class ProjectV2Api
 * @extends {BaseAPI}
 */
export class ProjectV2Api extends BaseAPI {
    /**
     * 
     * @summary Create a new project
     * @param {ProjectPostRequest} projectPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectV2Api
     */
    public createProject(projectPostRequest: ProjectPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectV2ApiFp(this.configuration).createProject(projectPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all company projects between dates
     * @param {string} companyId 
     * @param {string} from 
     * @param {string} to 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectV2Api
     */
    public getCompanyProjectsBetweenDates(companyId: string, from: string, to: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectV2ApiFp(this.configuration).getCompanyProjectsBetweenDates(companyId, from, to, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company root projects between dates
     * @param {string} companyId 
     * @param {string} from 
     * @param {string} to 
     * @param {string} [authorization] 
     * @param {'active' | 'deleted'} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectV2Api
     */
    public getCompanyRootProjectsBetweenDates(companyId: string, from: string, to: string, authorization?: string, status?: 'active' | 'deleted', options?: AxiosRequestConfig) {
        return ProjectV2ApiFp(this.configuration).getCompanyRootProjectsBetweenDates(companyId, from, to, authorization, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company root projects
     * @param {string} companyId 
     * @param {string} query 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectV2Api
     */
    public getCompanyRootProjectsNew(companyId: string, query: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectV2ApiFp(this.configuration).getCompanyRootProjectsNew(companyId, query, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search active projects from list, paginated
     * @param {string} companyId 
     * @param {string} query 
     * @param {'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle'} sortField 
     * @param {string} [authorization] 
     * @param {Array<string>} [projectIds] 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectV2Api
     */
    public getPaginatedActiveProjectsByIds(companyId: string, query: string, sortField: 'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle', authorization?: string, projectIds?: Array<string>, page?: number, sortDirection?: 'ASC' | 'DESC', fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return ProjectV2ApiFp(this.configuration).getPaginatedActiveProjectsByIds(companyId, query, sortField, authorization, projectIds, page, sortDirection, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project by id
     * @param {string} projectId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectV2Api
     */
    public getProjectById(projectId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectV2ApiFp(this.configuration).getProjectById(projectId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get projects by IDs
     * @param {string} companyId 
     * @param {Array<string>} [projectIds] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectV2Api
     */
    public getProjectsByIds(companyId: string, projectIds?: Array<string>, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectV2ApiFp(this.configuration).getProjectsByIds(companyId, projectIds, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get projects by parent ID
     * @param {string} projectId 
     * @param {'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle'} sortField 
     * @param {string} [query] 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectV2Api
     */
    public getProjectsByParentId(projectId: string, sortField: 'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle', query?: string, page?: number, sortDirection?: 'ASC' | 'DESC', authorization?: string, options?: AxiosRequestConfig) {
        return ProjectV2ApiFp(this.configuration).getProjectsByParentId(projectId, sortField, query, page, sortDirection, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get projects by parent ID (List)
     * @param {string} projectId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectV2Api
     */
    public getProjectsByParentIdAsList(projectId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectV2ApiFp(this.configuration).getProjectsByParentIdAsList(projectId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get public project by id
     * @param {string} projectId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectV2Api
     */
    public getPublicProjectInfoById(projectId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectV2ApiFp(this.configuration).getPublicProjectInfoById(projectId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get public projects by list of ids
     * @param {Array<string>} projectIds 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectV2Api
     */
    public getPublicProjectInfoByIds(projectIds: Array<string>, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectV2ApiFp(this.configuration).getPublicProjectInfoByIds(projectIds, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get users project between dates
     * @param {string} companyId 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectV2Api
     */
    public getUserProjects(companyId: string, from?: string, to?: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectV2ApiFp(this.configuration).getUserProjects(companyId, from, to, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get users root project on company
     * @param {string} companyId 
     * @param {'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle'} sortField 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectV2Api
     */
    public getUserRootProjects(companyId: string, sortField: 'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle', page?: number, sortDirection?: 'ASC' | 'DESC', authorization?: string, options?: AxiosRequestConfig) {
        return ProjectV2ApiFp(this.configuration).getUserRootProjects(companyId, sortField, page, sortDirection, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search company projects
     * @param {string} companyId 
     * @param {string} query 
     * @param {'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle'} sortField 
     * @param {string} [authorization] 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectV2Api
     */
    public searchCompanyProjects(companyId: string, query: string, sortField: 'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle', authorization?: string, page?: number, sortDirection?: 'ASC' | 'DESC', fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return ProjectV2ApiFp(this.configuration).searchCompanyProjects(companyId, query, sortField, authorization, page, sortDirection, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company root projects between dates
     * @param {string} companyId 
     * @param {'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle'} sortField 
     * @param {string} [query] 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectV2Api
     */
    public searchCompanyRootProjects(companyId: string, sortField: 'Title' | 'ProjectNumber' | 'Created' | 'PhaseTitle', query?: string, page?: number, sortDirection?: 'ASC' | 'DESC', authorization?: string, options?: AxiosRequestConfig) {
        return ProjectV2ApiFp(this.configuration).searchCompanyRootProjects(companyId, sortField, query, page, sortDirection, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set project last updated timestamp
     * @param {string} projectId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectV2Api
     */
    public setProjectUpdatedTimestamp(projectId: string, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectV2ApiFp(this.configuration).setProjectUpdatedTimestamp(projectId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update project
     * @param {string} projectId 
     * @param {ProjectPutRequest} projectPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectV2Api
     */
    public updateProject(projectId: string, projectPutRequest: ProjectPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectV2ApiFp(this.configuration).updateProject(projectId, projectPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Public View flag on project resources
     * @param {string} resourceId 
     * @param {'task' | 'project' | 'company' | 'deviation' | 'checklist' | 'checklistItem' | 'documentation' | 'documentationConnection' | 'location' | 'ehs' | 'order' | 'internalRoutine' | 'progressPlan'} resourceType 
     * @param {Array<PublicResourcePutRequest>} publicResourcePutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectV2Api
     */
    public updatePublicResources(resourceId: string, resourceType: 'task' | 'project' | 'company' | 'deviation' | 'checklist' | 'checklistItem' | 'documentation' | 'documentationConnection' | 'location' | 'ehs' | 'order' | 'internalRoutine' | 'progressPlan', publicResourcePutRequest: Array<PublicResourcePutRequest>, authorization?: string, options?: AxiosRequestConfig) {
        return ProjectV2ApiFp(this.configuration).updatePublicResources(resourceId, resourceType, publicResourcePutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
