import {
  CompanyProjectPhasePostAllRequest,
  PhaseApi,
  ProjectPhaseEntity,
  ProjectPhasePutAllRequest
} from "../../.generated/api";
import environment from "../../environment";
import { apiInstance } from "./ApiHttpBase";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { createQuery } from "react-query-kit";

const phasesApi = new PhaseApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export const useGetProjectPhasesByProjectId = createQuery({
  queryKey: ["getProjectPhasesByProjectId"],
  fetcher: async (variables: { projectId: string }) => {
    const response = await phasesApi.getProjectPhases(variables.projectId);
    return response.data;
  }
});

export const projectPhaseQueries = createQueryKeys("projectPhase", {
  getByProjectId: (projectId: string) => ({
    queryKey: [projectId],
    queryFn: async () => {
      const response = await phasesApi.getProjectPhases(projectId);
      return response.data;
    }
  })
});

export async function getProjectPhases(projectId: string) {
  const { data } = await phasesApi.getProjectPhases(projectId);

  return data;
}

export const useGetProjectPhasesByCompanyId = createQuery({
  queryKey: ["getProjectPhasesByCompanyId"],
  fetcher: async (variables: { companyId: string }) => {
    const { data } = await phasesApi.getCompanyProjectPhases(
      variables.companyId
    );

    return data;
  }
});

export async function updateAllCompanyProjectPhases(
  companyId: string,
  request: CompanyProjectPhasePostAllRequest
) {
  await phasesApi.updateAllCompanyProjectPhases(companyId, request);
}

// ---------------------------------------------- PROJECT PHASES

export const phaseQueries = createQueryKeys("projectPhase", {
  getByProjectId: (projectId: string) => ({
    queryKey: [projectId],
    queryFn: async () => {
      const response = await phasesApi.getProjectPhases(projectId);
      return response.data;
    }
  })
});

export async function updateCurrentProjectPhase(projectId: string, id: string) {
  await phasesApi.updateCurrentProjectPhase(projectId, id);
}

export async function updateAllPhasesOnProject(
  projectId: string,
  request: ProjectPhasePutAllRequest
) {
  await phasesApi.updateAllProjectPhases(projectId, request);
}

export type PhasesWithCurrentPhase = ProjectPhaseEntity & {
  isCurrentPhase?: boolean;
};
