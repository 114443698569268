import {
  DeviationApi,
  DeviationDeviationTypeEnum,
  DeviationPostRequest,
  DeviationPriorityEnum,
  DeviationPutRequest,
  DeviationStatusEnum,
  DeviationStatusRequest
} from "../../.generated/api";
import environment from "../../environment";
import { apiInstance } from "./ApiHttpBase";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { createQuery } from "react-query-kit";

const deviationApi = new DeviationApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export type DeviationSortField =
  | "Title"
  | "Created"
  | "Type"
  | "CreatedBy"
  | "Cost"
  | "Status"
  | "Priority";

export type DeviationFormInputs = {
  title: string;
  description: string;
  assignee?: { id: string; name: string };
  deadline?: string;
  priority?: DeviationPriorityEnum;
  deviationType?: DeviationDeviationTypeEnum;
  cause?: string;
  preventiveMeasures?: string;
  cost?: string;
  hours?: string;
  recipients: string[];
};

export const useGetDeviationById = createQuery({
  queryKey: ["getDeviationById"],
  fetcher: async (variables: { deviationId: string }) => {
    const response = await deviationApi.getDeviationById(variables.deviationId);
    return response.data;
  }
});

export const deviationQueries = createQueryKeys("deviation", {
  getByResourceId: (resourceId: string) => ({
    queryKey: ["getByResourceId", resourceId],
    queryFn: async () => {
      const response = await deviationApi.getDeviationsOnResource(resourceId);
      return response.data;
    }
  }),
  getByProjectId: (projectId: string) => ({
    queryKey: ["getByProjectId", projectId],
    queryFn: async () => {
      const response = await deviationApi.getDeviationsByProjectId(projectId);
      return response.data;
    }
  }),
  getAllPublicByProjectId: (projectId: string) => ({
    queryKey: ["getAllPublicByProjectId", projectId],
    queryFn: async () => {
      const response =
        await deviationApi.getAllPublicDeviationsByProjectId(projectId);
      return response.data;
    }
  })
});

export async function getDeviationsOnResource(
  resourceId: string,
  resourceType: "task" | "project" | "order"
) {
  const { data } = await deviationApi.getDeviationsOnResource(
    resourceId,
    resourceType
  );

  return data;
}

export const useGetDeviationsByCompanyId = createQuery({
  queryKey: ["getDeviationsByCompanyId"],
  fetcher: async (variables: {
    companyId: string;
    page: number;
    sortDirection: "ASC" | "DESC";
    sortField: DeviationSortField;
    resourceType: "project" | "order";
    status?: "open" | "resolved";
    query?: string;
  }) => {
    const response = await deviationApi.getPaginatedDeviationsOnCompany(
      variables.companyId,
      variables.sortField,
      variables.page,
      variables.sortDirection,
      variables.query,
      variables.resourceType,
      variables.status
    );

    return response.data;
  }
});

export async function createDeviation(request: DeviationPostRequest) {
  const { data } = await deviationApi.postDeviation(request);

  return data;
}

export async function updateDeviation(
  deviationId: string,
  request: DeviationPutRequest
) {
  await deviationApi.patchDeviation(deviationId, request);
}

export async function updateDeviationStatus(
  deviationId: string,
  request: DeviationStatusRequest
) {
  await deviationApi.patchDeviationStatus(deviationId, request);
}

export async function deleteDeviation(deviationId: string) {
  await deviationApi.deleteDeviation(deviationId);
}

export function getDeviationStatusColor(status?: string | DeviationStatusEnum) {
  switch (status) {
    case "resolved":
      return "green";
    default:
      return "red";
  }
}

export function resolveDeviationStatus(status?: string | DeviationStatusEnum) {
  switch (status) {
    case "resolved":
      return "resolved";
    default:
      return "open";
  }
}

export function resolveDeviationType(type?: DeviationDeviationTypeEnum) {
  switch (type) {
    case "RUI":
      return "RUH (rapport om uønsket hendelse)";
    case "TechnicalDeviation":
      return "Teknisk Avvik";
    case "SelfReport":
      return "Endringsmelding";
    case "EhsDeviation":
      return "ehsDeviation";
    case "ChecklistDeviation":
      return "Sjekklisteavvik";
    default:
      return "-";
  }
}

export function resolveDeviationPriority(type?: DeviationPriorityEnum) {
  switch (type) {
    case "Low":
      return "low";
    case "Medium":
      return "medium";
    case "High":
      return "high";
    default:
      return "-";
  }
}

export function resolveDeviationPriorityColor(type?: DeviationPriorityEnum) {
  switch (type) {
    case "Low":
      return "bg-system-blue-100";
    case "Medium":
      return "bg-warning";
    case "High":
      return "bg-system-red-100";
    default:
      return "";
  }
}
