import {
  FileApi,
  FilePostRequestFileResourceTypeEnum,
  FileResponse,
  SignedUrlResponse
} from "../../.generated/api";
import { apiInstance } from "./ApiHttpBase";
import axios, { AxiosProgressEvent } from "axios";
import environment from "../../environment";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { createQuery } from "react-query-kit";

const fileApi = new FileApi(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);
const defaultInstance = axios.create();

export async function getRootFiles(
  resourceId: string
): Promise<FileResponse[]> {
  const response = await fileApi.getRootFiles(resourceId);
  return response.data;
}

export const fileQueries = createQueryKeys("file", {
  getByResourceId: (resourceId: string) => ({
    queryKey: ["getFilesByResourceId", resourceId],
    queryFn: async () => {
      const response = await fileApi.getFilesByResourceId(resourceId);
      return response.data;
    }
  })
});

export const useGetRootFiles = createQuery({
  queryKey: ["getRootFiles"],
  fetcher: async (variables: { resourceId: string }) => {
    const response = await fileApi.getRootFiles(variables.resourceId);
    return response.data;
  }
});

export async function uploadFile(
  file: File,
  resourceId: string,
  onProgress?: (e: AxiosProgressEvent) => void,
  parentId?: string,
  fileResourceType?: FilePostRequestFileResourceTypeEnum
): Promise<FileResponse> {
  const signedUrlResponse = await getSignedUrl(resourceId, file.type);
  const id = signedUrlResponse.id;

  const body = await file.arrayBuffer();
  const formattedName = file.name.replace(/[^\x20-\x7E]/g, "_");
  const headers = {
    "Content-Disposition": `filename=${formattedName}`,
    "Content-Type": file.type
  };
  await defaultInstance.put(signedUrlResponse.url, body, {
    headers: headers,
    onUploadProgress: onProgress
  });

  const response = await fileApi.createFile(resourceId, {
    id: id,
    name: file.name,
    fileType: file.type,
    parent: parentId,
    fileResourceType
  });
  return response.data;
}

export async function getSignedUrl(
  resourceId: string,
  contentType: string
): Promise<SignedUrlResponse> {
  const response = await fileApi.getUploadUrl(resourceId, {
    contentType: contentType
  });

  return response.data;
}

export async function updateFile(
  id: string,
  resourceId: string,
  name: string,
  description?: string,
  parent?: string
): Promise<void> {
  await fileApi.update(id, resourceId, {
    name: name,
    description: description,
    parent: parent
  });
}

export async function deleteFile(
  id: string,
  resourceId: string
): Promise<void> {
  await fileApi.deleteFile(id, resourceId);
}

export async function getFilesByResourceId(resourceId: string) {
  const response = await fileApi.getFilesByResourceId(resourceId);
  return response.data;
}
