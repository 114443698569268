import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const createOrderSchema = z.object({
  facilityId: z.string().optional(),
  serviceContractId: z.string().optional(),
  customerId: z.string().optional(),
  date: z.string().optional()
});

export const Route = createFileRoute("/_protected/dashboard/orders/new/")({
  validateSearch: createOrderSchema
});
