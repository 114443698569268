import classNames from "classnames";
import React, { ComponentPropsWithoutRef, useMemo } from "react";

import Icon from "./Icon";

type ButtonSpinnerProps = {
  isSmall?: boolean;
  theme?: "white" | "red" | "green" | "flat";
};

export function ButtonSpinner({ theme, isSmall = false }: ButtonSpinnerProps) {
  return (
    <div className="grid h-full w-full place-items-center">
      <svg
        className={classNames(
          "animate-spin",
          {
            "stroke-white text-primary-90": theme === "green",
            "stroke-white text-system-red-90": theme === "red",
            "stroke-system-green-100 text-system-green-90":
              theme === "white" || theme === "flat"
          },
          isSmall ? "h-4 w-4" : "h-6 w-6"
        )}
        viewBox={`0 0 100 100`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx={50}
          cy={50}
          r={43}
          stroke={"currentStroke"}
          strokeWidth={12}
          fill={"none"}
        />
        <circle
          cx={50}
          cy={50}
          r={43}
          strokeDasharray={"100"}
          stroke={"currentColor"}
          strokeWidth={12}
          fill={"none"}
        />
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  );
}

type ButtonProps = Omit<ComponentPropsWithoutRef<"button">, "disabled"> & {
  className?: string;
  icon?: string;
  theme?: "white" | "red" | "green" | "flat";
  isSmall?: boolean;
  isLoading?: boolean;
  noMinWidth?: boolean;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isLeftIcon?: boolean;
  isFixedLength?: boolean;
};

export default function Button({
  icon,
  theme = "green",
  children,
  className,
  isSmall = false,
  isLoading = false,
  isDisabled = false,
  noMinWidth = false,
  isFullWidth = false,
  isLeftIcon = false,
  isFixedLength = false,
  ...props
}: ButtonProps) {
  const isLightTheme = useMemo(
    () => ["white", "secondary"].includes(theme),
    [theme]
  );

  return (
    <button
      className={classNames(
        "focus-visible:outline-12 whitespace-nowrap rounded-1 transition focus-visible:outline-secondary-90 active:scale-[0.98]",
        {
          "bg-system-accent-100 text-white hover:bg-system-accent-105":
            theme === "green",
          "bg-system-red-100 text-white hover:bg-system-red-105":
            theme === "red",
          "border-[1px] border-system-accent-90 bg-primary-5 text-system-accent-110 hover:bg-primary-10 active:border-primary-100 active:bg-primary-90":
            theme === "white",
          "bg-white text-secondary-110 hover:bg-primary-5 active:bg-primary-10":
            theme === "flat",
          "cursor-not-allowed !outline-none ": isDisabled,
          "!bg-system-neutral-10 !text-system-neutral-100":
            isDisabled && !isLightTheme,
          "!bg-system-neutral-10 !text-primary-110": isDisabled && isLightTheme,
          "w-32": isFixedLength,
          "w-full": isFullWidth
        },
        isSmall
          ? "min-w-[3.5rem] px-2 py-[7px] text-sm"
          : "h-12 min-w-[7.5rem] px-4 py-3",
        {
          "!min-w-[0]": noMinWidth
        },
        className
      )}
      disabled={isDisabled}
      type="button"
      {...props}
      onClick={!isLoading ? props.onClick : undefined}
    >
      {isLoading ? (
        <ButtonSpinner theme={theme} isSmall={isSmall} />
      ) : (
        <div
          className={classNames(
            "flex w-full items-center justify-center gap-0.5",
            {
              "flex-row-reverse": isLeftIcon,
              "justify-between": isFixedLength
            }
          )}
        >
          <span className={"truncate"}>{children}</span>
          {icon && (
            <Icon
              icon={icon}
              className={classNames({
                "text-[18px]": isSmall,
                "text-[26px]": !isSmall
              })}
            />
          )}
        </div>
      )}
    </button>
  );
}
