import {
  ProjectPostRequest,
  ProjectV2Api,
  PublicResourcePutRequest
} from "../../.generated/api";
import environment from "../../environment";
import { apiInstance } from "./ApiHttpBase";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { createQuery } from "react-query-kit";
import { SortDirection } from "../customerModule/SalesOpportunityApi";

export type QueryOptions = {
  keepPreviousData?: boolean;
  enabled?: boolean;
};

const projectApiV2 = new ProjectV2Api(
  undefined,
  environment.skwApiNewBaseUrl,
  apiInstance
);

export const useGetPublicProjectInfoByIds = createQuery({
  queryKey: ["getPublicProjectInfoByIds"],
  fetcher: async (variables: { projectIds: string[] }) => {
    const response = await projectApiV2.getPublicProjectInfoByIds(
      variables.projectIds
    );
    return response.data;
  }
});

export const useGetPublicProjectById = createQuery({
  queryKey: ["getPublicProjectById"],
  fetcher: async (variables: { projectId: string }) => {
    const response = await projectApiV2.getPublicProjectInfoById(
      variables.projectId
    );
    return response.data;
  }
});

export const useGetProjectById = createQuery({
  queryKey: ["getProjectById"],
  fetcher: async (variables: { projectId: string }) => {
    const response = await projectApiV2.getProjectById(variables.projectId);
    return response.data;
  }
});

export const useSearchCompanyProjects = createQuery({
  queryKey: ["searchCompanyProjects"],
  fetcher: async (variables: {
    companyId: string;
    query?: string;
    page?: number;
    sortField?: "Title" | "ProjectNumber" | "Created" | "PhaseTitle";
    sortDirection?: "ASC" | "DESC";
    fromDate?: Date;
    toDate?: Date;
  }) => {
    const response = await projectApiV2.searchCompanyProjects(
      variables.companyId,
      variables.query ?? "",
      variables.sortField ?? "Title",
      undefined,
      variables.page,
      variables.sortDirection ?? "DESC",
      variables.fromDate?.toISOString(),
      variables.toDate?.toISOString()
    );
    return response.data;
  }
});

export const useSearchProjectsByParentId = createQuery({
  queryKey: ["searchProjectsByParentId"],
  fetcher: async (variables: {
    parentId: string;
    query: string;
    page?: number;
    sortField?: "Title" | "ProjectNumber";
    sortDirection?: "ASC" | "DESC";
  }) => {
    const response = await projectApiV2.getProjectsByParentId(
      variables.parentId,
      variables.sortField ?? "Title",
      variables.query,
      variables.page,
      variables.sortDirection ?? "DESC"
    );

    return response.data;
  }
});

export const useSearchActiveProjectsByIds = createQuery({
  queryKey: ["searchActiveProjectsByIds"],
  fetcher: async (variables: {
    companyId: string;
    projectIds: string[];
    query: string;
    page: number;
    sortField: "Title" | "ProjectNumber" | "Created";
    sortDirection: "ASC" | "DESC";
    fromDate?: Date;
    toDate?: Date;
  }) => {
    const response = await projectApiV2.getPaginatedActiveProjectsByIds(
      variables.companyId,
      variables.query,
      variables.sortField,
      undefined,
      variables.projectIds,
      variables.page,
      variables.sortDirection ?? "DESC",
      variables.fromDate?.toISOString(),
      variables.toDate?.toISOString()
    );
    return response.data;
  }
});

export const projectQueries = createQueryKeys("project", {
  getByCompanyId: (
    companyId: string,
    query: string = "",
    page?: number,
    sortField: "Title" | "ProjectNumber" | "Created" = "Title",
    sortDirection: SortDirection = "DESC",
    fromDate?: Date,
    toDate?: Date
  ) => ({
    queryKey: [
      companyId,
      query,
      page,
      sortDirection,
      sortField,
      fromDate,
      toDate
    ],
    queryFn: async () => {
      const response = await projectApiV2.searchCompanyProjects(
        companyId,
        query,
        sortField,
        undefined,
        page,
        sortDirection,
        fromDate?.toISOString(),
        toDate?.toISOString()
      );
      return response.data;
    }
  }),
  getById: (projectId: string) => ({
    queryKey: [projectId],
    queryFn: async () => {
      const response = await projectApiV2.getProjectById(projectId);
      return response.data;
    }
  }),
  getByParentId: (parentId: string, page?: number) => ({
    queryKey: [parentId, page],
    queryFn: async () => {
      const response = await projectApiV2.getProjectsByParentId(
        parentId,
        "ProjectNumber",
        "",
        page,
        "DESC"
      );
      return response.data;
    }
  })
});

export async function createProjectV2(request: ProjectPostRequest) {
  const response = await projectApiV2.createProject(request);
  return response.data;
}

export async function getProjectById(projectId: string) {
  const response = await projectApiV2.getProjectById(projectId);
  return response.data;
}

export async function getCompanyProjectsBetweenDates(
  companyId: string,
  from: string,
  to: string
) {
  const response = await projectApiV2.getCompanyProjectsBetweenDates(
    companyId,
    from,
    to
  );
  return response.data;
}

export async function getRootProjectsBetweenDates(
  companyId: string,
  from: string,
  to: string,
  status: "active" | "deleted" = "active"
) {
  const response = await projectApiV2.getCompanyRootProjectsBetweenDates(
    companyId,
    from,
    to,
    "",
    status
  );
  return response.data;
}

export async function getUserRootProjects(
  companyId: string,
  sortField: "Title" | "ProjectNumber" | "Created" | "PhaseTitle",
  sortDirection: "ASC" | "DESC",
  page: number
) {
  const result = await projectApiV2.getUserRootProjects(
    companyId,
    sortField,
    page,
    sortDirection
  );
  return result.data;
}

export async function getProjectsByIds(
  companyId: string,
  projectIds: string[]
) {
  const result = await projectApiV2.getProjectsByIds(companyId, projectIds);
  return result.data;
}

export async function setProjectUpdatedTimestamp(projectId: string) {
  await projectApiV2.setProjectUpdatedTimestamp(projectId);
}

export async function updatePublicResources(
  resourceId: string,
  resourceType: "order" | "project",
  request: PublicResourcePutRequest[]
) {
  await projectApiV2.updatePublicResources(resourceId, resourceType, request);
}
