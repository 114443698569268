import React from "react";
import Spinner from "../../components/common/spinner/spinner";

export const SuspenseFallback = () => {
  return (
    <div className={"flex items-center justify-center h-screen"}>
      <Spinner />
    </div>
  );
};
