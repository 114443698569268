import { createFileRoute } from "@tanstack/react-router";
import { checklistSchema } from "../../../../../router/Routes";
import { useGetChecklistById } from "../../../../../service/api/ChecklistApiV2";

export const Route = createFileRoute(
  "/_protected/dashboard/checklist/$checklistId/update"
)({
  validateSearch: checklistSchema,
  preloadStaleTime: 0,
  preloadGcTime: 0,
  loader: async ({ context: { queryClient }, params: { checklistId } }) => {
    return queryClient.fetchQuery({
      ...useGetChecklistById.getOptions({ checklistId })
    });
  }
});
